import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { SessionDetailFormatter, SessionDetail as SessionInfo } from "@edutrackr/services/common";
import { Button, Card, CardContent, CardHeader, CardTitle, Icons, Label, LabelDescription } from "@edutrackr/ui/components";

export interface SessionDetailProps {
  session: SessionInfo;
}

export function SessionDetail(props: SessionDetailProps) {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'components.sessionDetail',
  });
  const navigate = useNavigate();

  const onJoinSession = () => {
    navigate(`/sessions/current/${props.session.id}`);
  };

  return (
    <Card>
      <CardHeader className="border-b px-6 py-3">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-3 md:gap-4">
          <div className="flex flex-col items-center gap-2 w-full md:flex-row md:justify-center md:gap-4 lg:justify-start">
            <CardTitle className="flex flex-row items-center gap-2 py-1.5">
              <Icons.Calendar className="w-5 h-5 text-gray-400" />
              {t('title')}
            </CardTitle>
          </div>
          <div className="flex gap-2">
            {
              props.session.isSessionActive && (
                <Button className="group" onClick={onJoinSession}>
                  {t('buttons.join')}
                  <Icons.ArrowRight className="w-5 h-5 ml-2 transition-transform duration-300 group-hover:translate-x-1" />
                </Button>
              )
            }
          </div>
        </div>
      </CardHeader>
      <CardContent className="pt-4">
        <div className="grid gap-4 md:grid-cols-2">
          <div>
            <Label>{t('fields.status.label')}</Label>
            <LabelDescription className="flex fleflex flex-row items-center gap-1">
              {SessionDetailFormatter.sessionTypeLabel(props.session)}
              {
                props.session.isSessionActive && (
                  <span className="flex h-2 w-2 rounded-full bg-green-600 animate-pulse" aria-hidden="true"></span>
                )
              }
            </LabelDescription>
          </div>

          <div>
            <Label>{t('fields.course.label')}</Label>
            <LabelDescription>{props.session.name}</LabelDescription>
          </div>

          <div>
            <Label>{t('fields.organizer.label')}</Label>
            <LabelDescription>
              {props.session.organizer.firstName} {props.session.organizer.lastName}
            </LabelDescription>
          </div>

          <div>
            <Label>{t('fields.startDate.label')}</Label>
            <LabelDescription>
              {SessionDetailFormatter.startTimeLabel(props.session)}
            </LabelDescription>
          </div>

          <div>
            <Label>{t('fields.topic.label')}</Label>
            <LabelDescription>
              {props.session.topic}
            </LabelDescription>
          </div>

          <div>
            <Label>{t('fields.categories.label')}</Label>
            <LabelDescription>
              {props.session.categories.length > 0
                ? props.session.categories.map((category) => category.name).join(", ")
                : t('messages.noCategories')
              }
            </LabelDescription>
          </div>

          <div className="md:col-span-2">
            <Label>{t('fields.description.label')}</Label>
            <LabelDescription>
              {props.session.description}
            </LabelDescription>
          </div>

        </div>
      </CardContent>
    </Card>
  );
}

export default SessionDetail;
