interface MainContentContainerProps {
  children: React.ReactNode;
}

/**
 * Main content container component.
 */
export const MainContentContainer = ({ children }: MainContentContainerProps) => {

  return (
    <main className="@container/main flex-grow">
      <div className="flex-grow p-6 @3xl/main:py-8 @5xl/main:px-20">
        <div className="max-w-7xl mx-auto px-2 pb-6 @xl/main:pt-3 @5xl/main:px-14">
          {children}
        </div>
      </div>
    </main>
  )
};
