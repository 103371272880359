import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { Badge, Button, Icons, ScrollArea, Textarea, Title } from "@edutrackr/ui/components";
import { SessionDetail } from "@edutrackr/services/common";
import { AssistantChatSession } from "@edutrackr/services/common";
import { useLastSessionChatQuery } from "../../hooks/use-user-chat-session-query";
import { useStartNewSessionChatMutation } from "../../hooks/use-start-new-session-chat-mutation";
import { useAssistantAnswerMutation } from "../../hooks/use-assistant-answer-mutation";
import { TeacherQueryKeys } from "@edutrackr/shared/enums";
import { ChatMessage as AssistantChatMessage, MessageRole } from "@edutrackr/shared/types";
import { ChatErrorOverlay, ChatLoadingOverlay, ChatMessage, LoadingChatMessage } from "@edutrackr/ui/components";

const newBadgeEnabled = true;

export interface AssistantChatProps {
  session: SessionDetail;
}

export function AssistantChat(props: AssistantChatProps) {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'components.assistant.chat',
  });
  const queryClient = useQueryClient();

  const initialChatMessages: AssistantChatMessage[] = [
    {
      content: t("greeting"),
      role: MessageRole.Assistant,
      references: [],
    },
    //...sampleMessages,
  ];

  const [message, setMessage] = React.useState<string>("");
  const [textAreaHeight, setTextAreaHeight] = React.useState<number>(0);
  const messagesContainerRef = React.useRef<HTMLDivElement>(null);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const userChatSessionQuery = useLastSessionChatQuery(props.session.id);
  const startNewSessionChatMutation = useStartNewSessionChatMutation();
  const assistantAnswerMutation = useAssistantAnswerMutation();

  // Clean the chat session (only for visual purposes)
  const cleanChat = () => {
    queryClient.invalidateQueries({
      queryKey: [TeacherQueryKeys.GetLastSessionChat, props.session.id]
    });
    // Clean mesages
    queryClient.setQueryData(
      [TeacherQueryKeys.GetLastSessionChat, props.session.id],
      (oldData: AssistantChatSession) => {
        return {
          ...oldData,
          messages: [],
        }
      }
    );
    setMessage("");
    textAreaRef.current?.focus();
  }

  // Scroll to the bottom of the messages
  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
    });
  };

  const addChatMessage = (message: AssistantChatMessage) => {
    queryClient.setQueryData(
      [TeacherQueryKeys.GetLastSessionChat, props.session.id],
      (oldData: AssistantChatSession) => {
        return {
          ...oldData,
          messages: [...oldData.messages, message],
        }
      }
    );
  }

  const handleSend = async () => {
    const chatSessionId = userChatSessionQuery.data?.id;
    if (assistantAnswerMutation.isPending || !chatSessionId)
      return;

    const cleanedUserMessage = message.trim();
    if (cleanedUserMessage) {
      setMessage("");
      addChatMessage({
        content: cleanedUserMessage,
        role: MessageRole.User,
        references: []
      });
      setTimeout(() => {
        scrollToBottom();
      }, 10);

      const values = {
        id: chatSessionId,
        content: cleanedUserMessage,
      };
      assistantAnswerMutation.mutate(values, {
        onSuccess: (data) => {
          const response = data.data!;
          addChatMessage(response.answer);
          scrollToBottom();
        }
      });
    }
  };

  const handleStartNewConversation = async () => {
    const values = {
      sessionId: props.session.id,
    };
    startNewSessionChatMutation.mutate(values, {
      onSuccess: () => {
        cleanChat();
      }
    });
  }

  const handleTryAgain = async () => {
    startNewSessionChatMutation.reset();
    cleanChat();
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }

    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMessage((prev) => prev + "\n");
    }
  };

  React.useEffect(() => {
    textAreaRef.current?.focus();
  }, []);

  const chatMessages = [
    ...initialChatMessages,
    ...(userChatSessionQuery.data?.messages || []),
  ]

  if (
    chatMessages.length === 0 ||
    userChatSessionQuery.isFetching ||
    startNewSessionChatMutation.isPending
  ) {
    return <ChatLoadingOverlay />;
  }

  if (userChatSessionQuery.isError || startNewSessionChatMutation.isError) {
    return <ChatErrorOverlay onTryAgain={handleTryAgain} />;
  }

  return (
    <div className="flex flex-col flex-grow">
      <div className="@container flex justify-between items-center gap-4 px-4 py-3 border-b">
        <div className="flex items-center gap-3">
          <Icons.Bot className="w-6 h-6 text-gray-400" />
          <div className="flex items-center gap-2">
            <Title type="h4" className="hidden @2xs:block">{t("title")}</Title>
            {newBadgeEnabled && (
              <Badge className="px-1 py-0.5 text-3xs uppercase">
                {t("newBadge")}
              </Badge>
            )}
          </div>
        </div>
        <Button
          title={t("buttons.startNewConversation")}
          variant="secondary"
          size="sm"
          onClick={handleStartNewConversation}
          className="flex aspect-square"
        >
          <Icons.MessageCirclePlus className="h-5 w-5 flex-shrink-0" />
        </Button>
      </div>
      <div className="flex flex-grow">
        <ScrollArea
          viewportRef={messagesContainerRef}
          className="flex-grow"
          style={{
            maxHeight: `calc(100vh - (${85 + textAreaHeight}px + var(--header-height)))`
          }}
        >
          <div className="flex flex-col flex-grow gap-4 px-4 py-4">
            {chatMessages.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}
            {assistantAnswerMutation.isPending && <LoadingChatMessage />}
          </div>
        </ScrollArea>
      </div>
      <div className="flex gap-3 px-4 py-2 pb-3">
        <Textarea
          ref={textAreaRef}
          autoComplete="off"
          maxRows={10}
          value={message}
          onKeyDown={handleKeyPress}
          onChange={(event) => setMessage(event.target.value)}
          onHeightChange={(height) => setTextAreaHeight(height)}
          placeholder={t("fields.userMessage.placeholder")}
          className="rounded-md resize-none"
        />
        <Button
          className="rounded-md p-2 h-9"
          title={t("buttons.send")}
          disabled={!message.trim() || assistantAnswerMutation.isPending}
          onClick={handleSend}
        >
          <Icons.SendHorizontal className="w-[20px] h-[20px]" />
        </Button>
      </div>
    </div>
  );
}
