import React from "react";
import { type ChatMessage, MessageRole, MessageContentReference } from "@edutrackr/shared/types";
import { MarkdownRenderer, cn } from "@edutrackr/ui/components";
import { ContentReferenceCard } from "./content-reference-card";
import { useTranslation } from "react-i18next";
import { I18nCommonKeys } from "@edutrackr/shared/constants";

interface ChatMessageProps {
  message: ChatMessage;
}

/**
 * This function is used to get the references with the part number (if there are multiple references from the same source).
 */
const getReferencesWithPartNumber = (references: ChatMessage["references"], prefix: string) => {
  if (!references || references.length === 0) {
    return [];
  }

  // course_resource_id is used as the key to identify the references
  const totalReferencesMap = new Map<string, number>();
  // Generate the reference map and then modify the references
  references.forEach((reference) => {
    const key = reference.course_resource_id;
    const count = totalReferencesMap.get(key) ?? 0;
    totalReferencesMap.set(key, count + 1);
  });

  const referencesPartMap = new Map<string, number>();
  return references.map((reference) => {
    const key = reference.course_resource_id;
    const count = referencesPartMap.get(key) ?? 0;
    referencesPartMap.set(key, count + 1);

    const totalCount = totalReferencesMap.get(key) ?? 0;
    if (totalCount <= 1) {
      return reference;
    }

    return {
      ...reference,
      source: `${prefix} ${count + 1} - ${reference.source}`
    } as MessageContentReference;
  });
};

export function ChatMessage({ message }: ChatMessageProps) {
  const { t } = useTranslation();

  const messageReferences = React.useMemo(
    () => getReferencesWithPartNumber(message.references, t(I18nCommonKeys.components.chat.chatMessage.referencesPrefix)),
    [t, message.references]
  );

  return (
    <div
      className={cn(
        "text-sm flex flex-col gap-3 p-2 rounded-lg max-w-[80%]",
        message.role === "assistant" ? "self-start bg-accent text-primary-background rounded-tl-none" : "self-end bg-blue-500 text-primary-foreground rounded-tr-none",
      )}
    >
      <MarkdownRenderer>
        {message.content}
      </MarkdownRenderer>
      {message.role === MessageRole.Assistant && messageReferences.length > 0 && (
        <div className="flex flex-row flex-wrap gap-1">
          {messageReferences.map((reference, index) => (
            <ContentReferenceCard key={index} contentReference={reference} />
          ))}
        </div>
      )}
    </div>
  );
}
