import { ArrayUtils } from "@edutrackr/shared/utils";
import { NavItem } from "@edutrackr/shared/types";
import { Button } from "../button";
import { Icons } from "../icons";
import { Sheet, SheetContent, SheetTrigger } from "../sheet";
import TopNavbarItem from "./top-navbar-item";


export interface TopNavbarProps {
  company: string;
  logo: string;
  navigation?: NavItem[];

  /**
   * Right content of the navbar. Ideal for adding the user menu.
   */
  rightContent: React.ReactNode;
}

export function TopNavbar(props: TopNavbarProps) {
  return (
    <header className="sticky z-10 top-0 w-full flex gap-3 px-3 lg:px-4 h-14 md:h-14 supports-[backdrop-filter]:bg-white/60 bg-white/95 backdrop-blur shadow-sm">
      <nav className="flex justify-between items-center mx-auto w-full max-w-7xl gap-8">
        <Sheet>

          <div className="flex gap-3 h-full">
            <div className="flex items-center gap-6">
              {props.navigation && !ArrayUtils.isEmpty(props.navigation) && (
                <SheetTrigger asChild className="sm:hidden">
                  <Button variant="ghost" className="rounded-full p-2 focus-visible:ring-slate-300 text-gray-700">
                    <Icons.Menu className="h-6 w-6" aria-hidden="true" />
                  </Button>
                </SheetTrigger>
              )}
            </div>
            <div className="flex flex-shrink-0 items-center">
              <img src={props.logo} alt={props.company} className="h-8 w-auto" />
            </div>
            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              {props.navigation && !ArrayUtils.isEmpty(props.navigation) && props.navigation.map((item, index) => (
                <TopNavbarItem key={index} variant="default" item={item} />
              ))}
            </div>
          </div>
          <div className="flex items-center gap-4 ml-auto">
            {props.rightContent}
          </div>

          <SheetContent side="left">
            <div className="flex flex-col gap-1 py-5">
              {props.navigation && !ArrayUtils.isEmpty(props.navigation) && props.navigation.map((item, index) => (
                <SheetTrigger key={index}>
                  <TopNavbarItem variant="mobile" item={item} />
                </SheetTrigger>
              ))}
            </div>
          </SheetContent>

        </Sheet>
      </nav>
    </header>
  );
}

export default TopNavbar;
