import { Alert, AlertDescription, AlertTitle, Button, Icons, cn } from "@edutrackr/ui/components";
import { useTranslation } from 'react-i18next';


export interface SessionsEmptyAlertProps {
  onRefresh: () => void;
  loading?: boolean;
}

export function SessionsEmptyAlert(props: SessionsEmptyAlertProps) {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'components.sessionsEmptyAlert',
  });
  return (
    <Alert variant="info" className="bg-white shadow-sm [&>svg]:top-6">
      <Icons.Clock className="h-4 w-4" />
      <div className="flex justify-between align-top gap-3">
        <div className="pt-2">
          <AlertTitle>
            {t('alerts.title.label')}
          </AlertTitle>
          <AlertDescription className="text-sm text-muted-foreground">
          {t('alerts.description.label')}
          </AlertDescription>
        </div>
        <div className="flex items-center">
          <Button className="mt-2 gap-2" onClick={props.onRefresh} disabled={props.loading}>
            <Icons.Refresh className={cn("h-4 w-4", props.loading && "animate-spin")} />
            {t('buttons.refresh')}
          </Button>
        </div>
      </div>
    </Alert>
  );
}

export default SessionsEmptyAlert;
