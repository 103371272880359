import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Icons, ScrollArea, ScrollBar, Title, cn } from "@edutrackr/ui/components";
import { ArrayUtils } from "@edutrackr/shared/utils";
import { SessionsList } from "../../components/sessions-list";
import { SessionDetail } from "../../components/session-detail";
import { SessionsEmptyAlert } from "../../components/sessions-empty-alert";
import { useUserSessionsQuery } from "../../hooks";


export interface SessionsPageProps { }

export function SessionsPage(props: SessionsPageProps) {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'pages.sessionsPage',
  });
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);

  const sessionsQuery = useUserSessionsQuery();

  const sessions = sessionsQuery.data?.items ?? [];
  const selectedSession = sessions.find((item) => item.id === selectedSessionId);

  return (
    <div className="flex flex-col gap-3">
      <Title type="h1" className="pb-3 flex items-center gap-2">
        {t('cards.title')}
        <Button variant="ghost" onClick={() => sessionsQuery.refetch()} disabled={sessionsQuery.isFetching}>
          <Icons.Refresh
            className={cn(
              "w-4 h-4 text-muted-foreground cursor-pointer",
              sessionsQuery.isFetching && "animate-spin"
            )}
          />
        </Button>
      </Title>
      {
        ArrayUtils.isEmpty(sessions)
          ? (
            <SessionsEmptyAlert onRefresh={() => sessionsQuery.refetch()} loading={sessionsQuery.isFetching} />
          )
          : (
            <ScrollArea className="w-full rounded-lg">
              <div className="flex flex-row gap-4 min-w-[640px]">
                <div className="w-1/3 flex flex-col gap-1">
                  <SessionsList items={sessions} selected={selectedSessionId} onSelect={setSelectedSessionId} showActiveIndicator />
                  <p className="text-sm min-h-5 text-muted-foreground self-start">
                    {
                      sessionsQuery.isFetching && t('cards.fetchingSessions')
                    }
                  </p>
                </div>

                <div className="w-2/3">
                  {
                    selectedSession
                      ? <SessionDetail session={selectedSession} />
                      : <div className="flex flex-col items-center justify-center h-full">
                        <div className="text-2xl text-muted-foreground border-1 pb-5">{t('cards.selectSession')}</div>
                      </div>
                  }
                </div>
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          )
      }
    </div>
  );
}

export default SessionsPage;
