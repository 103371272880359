import { useEffect, useRef } from "react";
import { useExternalScript } from "../../../shared/helpers/ai-sdk/externalScriptsLoader";
import { getAiSdkControls } from "../../../shared/helpers/ai-sdk/loader";
import DominantEmotionComponent from "./ai-sdk/DominantEmotionComponent";
import EngagementComponent from "./ai-sdk/EngagementComponent";
import EmotionBarsComponent from "./ai-sdk/EmotionBarsComponent";
import FaceTrackerComponent from "./ai-sdk/FaceTrackerComponent";


export function AiSdkCamera() {
  const mphToolsState = useExternalScript("https://edutrackr-cdn.pages.dev/web-ai/mphtools.js");
  const aiSdkState = useExternalScript("https://edutrackr-cdn.pages.dev/web-ai/ai-sdk.js");
  const videoEl = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    videoEl.current = document.getElementById("videoEl") as HTMLVideoElement;
    async function getAiSdk() {
      if (aiSdkState === "ready" && mphToolsState === "ready") {
        const { source, start } = await getAiSdkControls();
        await source.useCamera({
          toVideoElement: document.getElementById("videoEl"),
        });
        await start();

      }

    }
    getAiSdk();
  }, [aiSdkState, mphToolsState]);

  return (
    <div style={{ display: "flex", gap: "2rem", flexDirection: "column", alignItems: "center" }}>
      <div style={{ display: "flex", gap: "4rem", flexDirection: "row", alignItems: "center" }}>
      <EngagementComponent></EngagementComponent>
        <div style={{ display: "flex", gap: "1rem", flexDirection: "column", alignItems: "center" }}>
          <DominantEmotionComponent></DominantEmotionComponent>
          <EmotionBarsComponent></EmotionBarsComponent>
        </div>
      </div>
      <div style={{ width: "640px", height: "480px", position: "relative" }}>
        <video id="videoEl"></video>
        <FaceTrackerComponent videoEl={videoEl}></FaceTrackerComponent>
      </div>
    </div>
  );
}

export default AiSdkCamera;
