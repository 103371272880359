import { useTranslation } from 'react-i18next';
import { Icons, cn } from "@edutrackr/ui/components";
import { SessionDetail, SessionMinimalFormatter } from "@edutrackr/services/common";

export interface SessionsListItemProps {
  item: SessionDetail;
  isSelected: boolean;
  onSelect: () => void;
  showActiveIndicator?: boolean;
}

export function SessionsListItem(props: SessionsListItemProps) {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'components.sessionsListItem',
  });
  return (
<button
      key={props.item.id}
      type="button"
      className={cn(
        "flex flex-col relative items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
        props.isSelected && "bg-muted"
      )}
      onClick={props.onSelect}
    >
      <div className="flex w-full flex-col gap-1">
        <div
          className="text-xs text-balance text-muted-foreground"
          title={SessionMinimalFormatter.startTimeTooltip(props.item)}
        >
          {SessionMinimalFormatter.startTimeLabel(props.item)}
        </div>
        <div className="font-semibold text-md mb-1">{props.item.topic}</div>
        <div className="flex flex-row items-start text-xs text-muted-foreground">
          <Icons.Book className="w-3 h-3 mt-0.5 mr-1 flex-shrink-0" />
          <span>{props.item.name}</span>
        </div>
        <div className="flex flex-row items-start text-xs text-muted-foreground">
          <Icons.User className="w-3 h-3 mt-0.5 mr-1 flex-shrink-0" />
          <span>{props.item.organizer.firstName} {props.item.organizer.lastName}</span>
        </div>
      </div>
      {props.showActiveIndicator && props.item.isSessionActive && (
        <span
          title={t('sessionStarted.label')}
          className="absolute top-2 right-2 flex h-2 w-2 rounded-full bg-green-600 animate-pulse"
        />
      )}
    </button>
  );
}

export default SessionsListItem;
